<template>
  <b-card>

    <div
      v-if="isLoading"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="userData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching user data
        </h4>
        <div class="alert-body">
          No user found with this user id. Check
          <b-link
            class="alert-link"
            :to="{ name: 'users'}"
          >
            User List
          </b-link>
          for other users.
        </div>
      </b-alert>

      <div
        v-if="userData"
      >

        <!-- Media -->
        <b-media class="mb-2">
          <template #aside>
            <b-avatar
              ref="previewEl"
              :src="userData.avatar"
              :text="avatarText(userData.name)"
              size="90px"
              rounded
            />
          </template>
          <h4 class="mb-1">
            {{ userData.name }}
          </h4>
          <!-- <div class="d-flex flex-wrap">
            <b-button
              variant="primary"
              @click="$refs.refInputEl.click()"
            >
              <input
                ref="refInputEl"
                type="file"
                class="d-none"
                @input="inputImageRenderer"
              >
              <span class="d-none d-sm-inline">Update</span>
              <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-1"
            >
              <span class="d-none d-sm-inline">Remove</span>
              <feather-icon
                icon="TrashIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
          </div> -->
        </b-media>

        <!-- User Info: Input Fields -->
        <b-form>
          <b-row>

            <!-- Field: Username -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Username"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="userData.userName"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Full Name -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Name"
                label-for="full-name"
              >
                <b-form-input
                  id="full-name"
                  v-model="userData.name"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Email -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.mail"
                  type="email"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Role -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="User Role"
                label-for="user-role"
              >
                <v-select
                  v-model="userData.role"
                  :options="roleOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
                />
                <!-- <b-form-input
                  id="user-role"
                  v-model="userData.role"
                /> -->
              </b-form-group>
            </b-col>

            <!-- Field: Status -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Status"
                label-for="user-status"
              >
                <v-select
                  v-model="userData.isEnabled"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-status"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>

        <!-- PERMISSION TABLE -->
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">Permisos para cargar archivos</span>
            </b-card-title>
          </b-card-header>
          <b-table
            striped
            responsive
            class="mb-0"
            :fields="tableColumns"
            :items="userData.permissions"
          >
            <template #cell(conditions)="data">
              {{ data.value.area_name }}
            </template>
            <template #cell(isEnabled)="data">
              <b-form-checkbox 
                v-model="data.item.isEnabled"
                :value="1"
                :unchecked-value="0"
              />
            </template>
          </b-table>
        </b-card>

        <!-- Action Buttons -->
        <b-button
          v-if="isLoadingBtn"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          disabled
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <b-spinner small />
          <span> Loading...</span>
        </b-button>
        <b-button
          v-else
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveData"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :to="{ name: 'users'}"
        >
          Cancel
        </b-button>
      </div>
    </div>

  </b-card>
</template>

<script>
import {
  BCard, BAlert, BLink,
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCardHeader, BCardTitle, BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { ref, getCurrentInstance } from 'vue'
import router from '@/router'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import useUsersList from './useUsersList'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,

    vSelect,
    CustomLoading,
  },
  setup() {
    const userData = ref(null)
    const permissionsData = ref([])
    const isLoading = ref(true)
    const isLoadingBtn = ref(false)
    
    const { roleOptions } = useUsersList()

    const vm = getCurrentInstance().proxy

    const statusOptions = [
      { label: 'Activo', value: 1 },
      { label: 'Inactivo', value: 0},
    ]

    const tableColumns = [
      { key: 'subject', label: 'area'},
      { key: 'isEnabled', label: 'modificar'},
    ]

    const saveData = () => {

      isLoadingBtn.value = true

      axios
      .post(`${process.env.VUE_APP_BASE_URL}/modify_user`, 
        { 
          data: userData.value
        }
      )
      .then(response => { 
        // console.log(response)
        vm.$swal({
          title: 'Datos cambiados!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          willClose: fechUserData
        }) 
      })
      .catch(error => {
        console.log(error)
        vm.$swal({
          title: 'Error al editar usuario!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      .finally(() => {
        isLoadingBtn.value = false
      })
    }

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      userData.avatar = base64
    })

    const fechUserData = () => {
      isLoading.value = true

      axios
      .post(`${process.env.VUE_APP_BASE_URL}/user_info`, 
        { id: router.currentRoute.params.id }
      )
      .then(response => { 
        userData.value = response.data.result
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
      .finally(() => {
        isLoading.value = false
      })
    }

    fechUserData()

    return {
      isLoading,
      isLoadingBtn,
      userData,
      avatarText,
      statusOptions,
      tableColumns,
      permissionsData,
      roleOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      saveData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
